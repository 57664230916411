import React, { useEffect } from "react";
//import js
//impot css
import "../css/mypay.css";
import { NavLink } from "react-router-dom";
import { passedWeekDate, readIdUser, DatatablePay } from "../functions/pays";

const Mypay = () => {
    const proccess = async () => {
        const input_classic_text = document.querySelectorAll('.input_classic_text');
        input_classic_text.forEach(hidden=>{
            hidden.disabled = true;
        });
        await passedWeekDate();
        await readIdUser();
        await DatatablePay();
    }
    useEffect(() => {
        proccess();
    }, []);
    return (
        <div className="mypay">
            {/*bar*/}
            <div className="bar_classic">
                {/*button to back*/}
                <NavLink className="button_back bg_transparent color_white" to={"/"}>Volver</NavLink>
                {/*tittle*/}
                <h2 className="h2_classic color_white">Mi pago semanal</h2>
            </div>
            {/*information*/}
            <div className="pop-up_window gap20px">
                {/*hours*/}
                <div className="row_classic">
                    <label htmlFor="hours" className="label_classic">Horas trabajadas:</label>  <input type="text" id="hours" className="input_classic_text" />
                </div>
                {/*total*/}
                <div className="row_classic">
                    <label htmlFor="total" className="label_classic">Acomulado:</label>  <input type="text" id="total" className="input_classic_text" />
                </div>
                {/*bonus*/}
                <div className="row_classic">
                    <label htmlFor="bonus" className="label_classic">Bono:</label>  <input type="text" id="bonus" className="input_classic_text" />
                </div>
                {/*loan*/}
                <div className="row_classic">
                    <label htmlFor="loan" className="label_classic">Descuento semanal:</label>  <input type="text" id="loan" className="input_classic_text" />
                </div>
                {/*total loan*/}
                <div className="row_classic">
                    <label htmlFor="totalLoan" className="label_classic">Total deuda:</label>  <input type="text" id="totalLoan" className="input_classic_text" />
                </div>
                {/*Salary loan*/}
                <div className="row_classic">
                    <label htmlFor="salary" className="label_classic">Total a Recibir:</label>  <input type="text" id="salary" className="input_classic_text" />
                </div>
                <h2 id="message_confirm" className="h2_classic"></h2>
            </div>
        </div>
    );
}
export default Mypay;