//week passed date
export const passedWeekDate = async () => {
    try {
        const response = await fetch(`https://api.favenllc.com/app/Main?passedWekk=true`, {
            method: 'GET'
        });
        const result = await response.json();
        console.log(result);
        if (result.status === 'success') {
            const dates = {
                'sunday': result.sunday,
                'saturday': result.saturday
            };
            sessionStorage.setItem('dates', JSON.stringify(dates));
        }
    } catch (error) {
        console.error(error);
    }

}

//read id user 
export const readIdUser = async () => {
    const key001ms = localStorage.getItem('Key001ms');
    const data = { key001ms };
    try {
        const response = await fetch('https://api.favenllc.com/information_user.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        const result = await response.json();
        if (result.status === 'success') {
            sessionStorage.setItem('user', result.id);
        }
    } catch (error) {
        console.error(error);
    }
}

//read bonus
export const readBonus = async () => {
    const user = sessionStorage.getItem('user');
    try {
        const response = await fetch(`https://api.favenllc.com/app/Main?readBonus=true&id=${user}`, {
            method: 'GET'
        });
        const result = await response.json();
        console.log(result);
        if (result.status === 'success') {
            sessionStorage.setItem('bonus', result.bonus);
            document.getElementById('bonus').value = result.bonus;
        } else {
            document.getElementById('bonus').value = '0.00';
        }
    } catch (error) {
        console.error(error);
    }
}

//weekly payment
export const weeklyPayment = async () => {
    try {
        const user = sessionStorage.getItem('user');
        const dates = JSON.parse(sessionStorage.getItem('dates'));
        const response = await fetch(`https://api.favenllc.com/app/Main?weeklyPayment=true&sunday=${dates.sunday}&saturday=${dates.saturday}&id=${user}`, {
            method: 'GET'
        });
        const result = await response.json();
        console.log(result);
        if (result.status === 'success') {
            if(result.payment === '0.00'){
                document.getElementById('loan').value = result.payment;
            }else{
                document.getElementById('loan').value = result.payment;
            }
        }else{
            document.getElementById('loan').value = result.message;
        }

    } catch (error) {
        console.error(error);
    }
}

//total loan by user each
export const totalLoanByUser = async ()=>{
    const user = sessionStorage.getItem('user');
    try {
        const response = await fetch(`https://api.favenllc.com/app/Main?totalLoanByUser=true&id=${user}`,{
            method: 'GET'
        });
        const result = await response.json();
        console.log(result);
        if(result.status === 'success'){
            if(result.totalLoan === '0.00'){
                document.getElementById('totalLoan').value = result.totalLoan;
            }else{
                document.getElementById('totalLoan').value = result.totalLoan;
            }
        }else{
            document.getElementById('totalLoan').value = result.message;
        }
    } catch (error) {
        console.error(error)
    }
}

//total to pay
export const totalToPay = async ()=>{
    const total = parseFloat(document.getElementById('total').value);
    const bonus = parseFloat(document.getElementById('bonus').value);
    const loan = parseFloat(document.getElementById('loan').value);
    const salary = (total+bonus) - loan;
    document.getElementById('salary').value = salary;
}

//list passedweek
export const listPassedWeek = async () => {
    const id = localStorage.getItem('Key001ms');

    //data
    const data = {
        id,
        week: true
    }


    try {
        //await
        const response = await fetch('https://api.favenllc.com/passedweek.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        const result = await response.json();

        console.log(result);

        if (result.status === 'success') {
            document.getElementById('hours').value = result.totalHours;
            document.getElementById('total').value = result.salaryUser;
            await readBonus();
            await weeklyPayment();
            await totalLoanByUser();
            await totalToPay();
            document.getElementById('message_confirm').textContent = 'El administrador aun no ha confirmado el pago.';
        } else {

        }

    } catch (error) {
        console.log('Error en la solicitud de carga al servidor');
    }

}

//search pay and put data
export const DatatablePay = async () => {
    const id = sessionStorage.getItem('user');
    const dates = JSON.parse(sessionStorage.getItem('dates'));
    const saturday = dates.saturday;
    const response = await fetch(`https://api.favenllc.com/app/Main?DatatablePay=true&id=${id}&saturday=${saturday}`, {
        method: 'GET'
    });
    const result = await response.json();
    console.log(result);
    if (result.status === 'success') {
        document.getElementById('hours').value = result.hours;
        document.getElementById('total').value = result.total;
        if (result.bonus === '0.00') {
            document.getElementById('bonus').value = 'Actualmente no tiene.';
        } else {
            document.getElementById('bonus').value = result.bonus;
        }
        if (result.payment === '0.00') {
            document.getElementById('loan').value = 'Actualmente no tiene.';
        } else {
            document.getElementById('loan').value = result.payment;
        }
        if (result.totalLoan === '0.00') {
            document.getElementById('totalLoan').value = 'Actualmente no tiene.';
        } else {
            document.getElementById('totalLoan').value = result.totalLoan;
        }
        document.getElementById('salary').value = result.salary;
        document.getElementById('message_confirm').textContent = 'Confirmado por el administrador.';
    } else {
        listPassedWeek();
    }
}